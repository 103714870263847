import {hexToRGB} from "../tools";
import theme, {LIST_COLOR_NAME} from "../../@chakra-ui/gatsby-plugin/theme";

export function movingAvg(array, countBefore, countAfter) {
  if (countAfter == undefined) countAfter = 0;
  const result = [];
  for (let i = 0; i < array.length; i++) {
    const subArr = array.slice(Math.max(i - countBefore, 0), Math.min(i + countAfter + 1, array.length));
    const avg = Math.round(subArr.reduce((a, b) => a + (isNaN(b) ? 0 : b), 0) / subArr.length*10)/10;
    result.push(avg);
  }
  return result;
}


export const setGradientColor = (canvas, color,heightFill) => {
    if (!color) return;
    const ctx = canvas.getContext("2d");
    //const gradient = ctx.createLinearGradient(0, 100, 900, 195);
    const gradient = ctx.createLinearGradient(0, 0, 0, heightFill);
    gradient.addColorStop(0, hexToRGB(color,0.9 ));
    gradient.addColorStop(0.7, hexToRGB(color,0.01 ));
    //gradient.addColorStop(0.5, color[300]);
    gradient.addColorStop(1, 'rgba(244, 238, 235,0)');
    return gradient;
  };


export function getColorFromNumColor(numColor){
    return theme.colors[LIST_COLOR_NAME[numColor%LIST_COLOR_NAME.length]]
}

const JSON_COLOR_TOPIC = {
    '24' :theme.colors.green,
    '25' :theme.colors.pink,
    '27' :theme.colors.blue,
    '29' :theme.colors.orange,
    '32' :theme.colors.purple,
    '33' :theme.colors.teal,
}
export function getColorFromTagIdStr(tagId){
    return JSON_COLOR_TOPIC.hasOwnProperty(tagId)?
                    JSON_COLOR_TOPIC[tagId]:theme.colors.gray
}